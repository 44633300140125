import InfoRoundIcon from '@rsuite/icons/InfoRound';
import PlusRoundIcon from '@rsuite/icons/PlusRound';
import MinusRoundIcon from '@rsuite/icons/MinusRound';

import { useFormik } from 'formik';
import React, { useEffect } from 'react';
import { Tooltip, Whisper } from 'rsuite';
import styled from 'styled-components';
import CustomButton from '../../../../components/Buttons/CustomButton';
import ColorPicker from '../../../../components/ColorPicker';
import FileUploader, { UploadedFile } from '../../../../components/FileUploader';
import ControlledNumericInput from '../../../../components/inputs/ControlledNumericInput';
import CustomInput from '../../../../components/inputs/CustomInput';
import CustomSelect, { SelectOption, SelectValue } from '../../../../components/inputs/CustomSelect';
import logoOverlayPlaceholder from '../../../../assets/logoOverlayPlaceholder.png';
import { ApplicationTheme } from '../../../../globalStyles';
import { getLogoPosition } from '../../../../helpers';

import { statusOptions } from '../../../UnitPage/Sections/Specifics/data';
import { CustomStatusOverlayI } from '../../../../store/settings/requests';
import CustomStatusSection from './CustomStatusSection';
import DefaultStatusSection from './DefaultStatusSection';
import { UpdateOverlayRequestData } from '../../../../store/settings/actions';
import { useAppMessageContext } from '../../../../appMessagesContext';

export interface OverlaySectionFormI {
  logoSize: string
  logo: null | UploadedFile
  logo_url: null | string
  defaultOverlay: SelectValue
  logoPlacement: SelectValue
  statusOverlay: string
  statusOverlaySize: string
  statusOverlayUrl: string
  statusOverlayFile: null | UploadedFile
  statusOverlayPlacement: SelectValue
  removeStatusOverlay: boolean
  upperText: SelectValue
  upperBannerColor: string
  upperTextColor: string
  lowerText: SelectValue
  lowerBannerColor: string
  lowerTextColor: string
  customStatus: CustomStatusOverlayI[]
  defaultStatus: CustomStatusOverlayI[]
}

type OverlaySectionProps = {
  onFormSubmit: (data: UpdateOverlayRequestData) => void
  initialValues: OverlaySectionFormI
  defaultOverlayOptions: SelectOption[]
  defaultStatusOverlays: CustomStatusOverlayI[]
  customStatusOverlays: CustomStatusOverlayI[]
  logoPlacementOptions: SelectOption[]
  statusOverlayPlacementOptions: SelectOption[]
  textOptions: SelectOption[]
}

function OverlaySectionForm({
  onFormSubmit,
  initialValues,
  defaultOverlayOptions,
  defaultStatusOverlays,
  customStatusOverlays,
  logoPlacementOptions,
  statusOverlayPlacementOptions,
  textOptions,
}: OverlaySectionProps) {
  const selectTooltip = (
    <StyledTooltip arrow={false}>
      <div>
        The
        <span className="highlighted"> Default Overlay Setting </span>
        is the default configuration that a unit will have when it is created. If you choose a different option within the Images section of the ADD / EDIT UNIT page for a unit your choice will override this Default Overlay Setting for that unit.
      </div>
    </StyledTooltip>
  );

  const saveTooltip = (
    <StyledTooltip arrow={false}>
      <div>
        If any changes are made to Overlay settings when selecting
        {' '}
        <span className="highlighted"> SAVE </span>
        {' '}
        all existing and future Inventory will be updated to reflect the changes.
      </div>
    </StyledTooltip>
  );

  const { showMessage } = useAppMessageContext();

  const handleSubmit = (data: OverlaySectionFormI) => {
    const customStatusValues = data.customStatus.map((item) => item.status_overlay);

    if (customStatusValues.some((customStatusValue: string) => customStatusValue?.trim() === '')) {
      showMessage({ type: 'error', message: 'Custom status labels must not be empty' });
      return;
    }

    const uniqueCustomStatusValues = [...new Set(customStatusValues)];
    if (customStatusValues.length !== uniqueCustomStatusValues.length) {
      showMessage({ type: 'error', message: 'Custom status labels must be unique' });
      return;
    }

    const formData: UpdateOverlayRequestData = {
      default_setting: data.defaultOverlay?.value ? String(data.defaultOverlay.value) : '',
      ...(data.logo && { logo: data.logo }),
      logo_placement: data.logoPlacement?.value ? String(data.logoPlacement.value) : '',
      logo_size: data.logoSize,
      ...(data.statusOverlayFile && { status_overlay_file: data.statusOverlayFile }),
      status_overlay: data.statusOverlay,
      status_overlay_size: data.statusOverlaySize,
      status_overlay_placement: data.statusOverlayPlacement?.value ? String(data.statusOverlayPlacement.value) : '',
      remove_status_overlay: data.removeStatusOverlay,
      upper_text: data.upperText?.value ? String(data.upperText.value) : '',
      upper_background_color: data.upperBannerColor,
      upper_text_color: data.upperTextColor,
      lower_text: data.lowerText?.value ? String(data.lowerText.value) : '',
      lower_background_color: data.lowerBannerColor,
      lower_text_color: data.lowerTextColor,
      custom_status: data.customStatus,
      default_status: data.defaultStatus,
    };
    onFormSubmit(formData);
  };

  const formik = useFormik({
    initialValues,
    onSubmit: handleSubmit,
  });
  const {
    setFieldValue, values: formikValues, resetForm,
  } = formik;

  useEffect(() => {
    resetForm({ values: initialValues });
  }, [initialValues]);

  console.log(formikValues, 'formikValues');

  const handleInputChange = (key: keyof OverlaySectionFormI, value: undefined | string | number | boolean | string[] | UploadedFile | SelectValue | SelectOption[] | CustomStatusOverlayI[]) => {
    setFieldValue(key, value);
  };

  const statusOptionsOverlay: CustomStatusOverlayI[] = statusOptions.map((statusOption) => ({
    status_overlay: statusOption?.value,
    status_overlay_url: '',
    status_overlay_placement: 'Disabled',
    status_overlay_size: '25',
  }));

  const handleRemoveCustomStatusAtIndex = (key: keyof OverlaySectionFormI, index: number) => {
    const tempData = [...formikValues.customStatus];
    tempData.splice(index, 1);

    handleInputChange('customStatus', tempData);
  };

  const handleRemoveDefaultStatusOverlay = () => {
    handleInputChange('defaultStatus', formikValues.defaultStatus?.map((item) => (item.status_overlay === formikValues.statusOverlay ? {
      ...item, status_overlay_url: null, status_overlay_file: null, remove_status_overlay: 1,
    } : item)));
  };

  const handleRemoveCustomStatusOverlay = () => {
    handleInputChange('customStatus', formikValues.customStatus?.map((item) => (item.status_overlay === formikValues.statusOverlay ? {
      ...item, status_overlay_url: null, status_overlay_file: null, remove_status_overlay: 1,
    } : item)));
  };

  const handleFileUpload = (files: UploadedFile[]) => {
    handleInputChange('logo', files[0]);
  };

  const defaultStatusOverlayOptions: SelectOption[] = [
    ...defaultStatusOverlays?.map((item: any) => ({ label: item?.status_overlay, value: item?.id.toString() })) || [],
  ];

  const handleCustomStatusOverlayFileUpload = (files: UploadedFile[]) => {
    handleInputChange('customStatus', formikValues.customStatus?.map((item) => (item.status_overlay === formikValues.statusOverlay ? { ...item, status_overlay_file: files[0] } : item)));
  };

  const customStatusOverlayOptions: SelectOption[] = [
    ...customStatusOverlays?.map((item: any) => ({ label: item?.status_overlay, value: item?.id.toString() })) || [],
  ];

  const handleDefaultStatusOverlayFileUpload = (files: UploadedFile[]) => {
    handleInputChange('defaultStatus', formikValues.defaultStatus?.map((item) => (item.status_overlay === formikValues.statusOverlay ? { ...item, status_overlay_file: files[0] } : item)));
  };

  const handleCustomStatusChange = (value: string, index: number) => {
    const tempData = [...formikValues.customStatus];
    tempData[index].status_overlay = value;
    handleInputChange('customStatus', tempData);
  };

  useEffect(() => {
    handleInputChange('customStatus', customStatusOverlays || []);
  }, [customStatusOverlays]);

  useEffect(() => {
    handleInputChange('defaultStatus', defaultStatusOverlays.length ? defaultStatusOverlays : statusOptionsOverlay || []);
  }, []);

  return (
    <Container>
      <div className="default-overlay-config">
        <div className="overlay-setting-section">
          <div className="overlay-setting-header">
            <div>
              <div className="section-title">
                Default Overlay Configuration
              </div>
              <p>
                Add a default location, phone and logo overlay
              </p>
            </div>
            <div className="select-with-tooltip">
              <div className="overlay-setting-select">
                <CustomSelect
                  label="Default Overlay Setting"
                  options={defaultOverlayOptions}
                  value={formikValues.defaultOverlay}
                  onChange={(val) => handleInputChange('defaultOverlay', val)}
                />
              </div>
              <Whisper placement="auto" controlId="control-id-hover" trigger="hover" speaker={selectTooltip}>
                <div>
                  <InfoIco />
                </div>
              </Whisper>
            </div>
          </div>
          <div className={`logo-overlay-preview ${getLogoPosition(String(formikValues.logoPlacement?.value))}`}>
            <div
              className="upper-text-overlay"
              style={{
                color: formikValues?.upperTextColor || '',
                backgroundColor: formikValues?.upperBannerColor || '',
                visibility: formikValues.upperText?.value === 'Disabled' ? 'hidden' : 'initial',
              }}
            >
              {formikValues.upperText?.value}
            </div>
            {(formikValues.logo || formikValues.logo_url)
                  && (
                  <img
                    className="uploaded-logo"
                    alt="logo"
                    src={formikValues.logo?.url || formikValues.logo_url || ''}
                    style={{
                      maxWidth: `${formikValues.logoSize}%`, // Incorporates logoSize
                      position: 'absolute',
                      zIndex: 2,
                    }}
                  />
                  )}
            <img src={logoOverlayPlaceholder} alt="logo-overlay" className="logo-overlay-back" />
            <div
              className="lower-text-overlay"
              style={{
                color: formikValues?.lowerTextColor || '',
                backgroundColor: formikValues?.lowerBannerColor || '',
                visibility: formikValues.lowerText?.value === 'Disabled' ? 'hidden' : 'initial',
              }}
            >
              {formikValues.lowerText?.value}
            </div>
          </div>
        </div>
        <div className="logo-overlay">
          <div className="logo-overlay-inputs">
            <div className="section-title">
              Logo Overlay
            </div>
            <div className="logo-overlay-settings">
              <div className="logo-placement-select">
                <CustomSelect
                  label="Logo Placement"
                  options={logoPlacementOptions}
                  value={formikValues.logoPlacement}
                  onChange={(val) => handleInputChange('logoPlacement', val)}
                />
              </div>
              <ControlledNumericInput
                wrapperClass="logo-size"
                label="Logo Size"
                value={formikValues.logoSize}
                onChange={(value) => handleInputChange('logoSize', value)}
              />
            </div>
            <div className="uploader">
              <FileUploader
                title="Add Logo for Overlay"
                subtitle="or drag and drop images within this box"
                acceptFiles={{
                  'image/jpeg': [],
                  'image/png': [],
                  'image/gif': [],
                }}
                onChange={handleFileUpload}
                initialFiles={formikValues.logo ? [formikValues.logo] : []}
                filesFromResponse={[{
                  id: 1,
                  name: '',
                  order: 0,
                  size: 1,
                  type: '',
                  url: formikValues.logo_url || '',
                }]}
                hidePreview
                multiple={false}
              />
            </div>
          </div>
          <div className="upper-text">
            <div className="section-title">
              Text Overlay (Upper)
            </div>
            <div className="text-overlay-inputs">
              <div className="text-to-display-select">
                <CustomSelect
                  label="Text to display"
                  options={textOptions}
                  value={formikValues.upperText}
                  onChange={(val) => handleInputChange('upperText', val)}
                />
              </div>
              <div className="color-picker-wrapper">
                <ColorPicker
                  label="Banner Color"
                  value={formikValues.upperBannerColor}
                  onChange={(val) => handleInputChange('upperBannerColor', val)}
                />
              </div>
              <div className="color-picker-wrapper">
                <ColorPicker
                  label="Text Color"
                  value={formikValues.upperTextColor}
                  onChange={(val) => handleInputChange('upperTextColor', val)}
                />
              </div>
            </div>
          </div>
          <div className="lower-text">
            <div className="section-title">
              Text Overlay (Lower)
            </div>
            <div className="text-overlay-inputs">
              <div className="text-to-display-select">
                <CustomSelect
                  options={textOptions}
                  label="Text to display"
                  value={formikValues.lowerText}
                  onChange={(val) => handleInputChange('lowerText', val)}
                />
              </div>
              <div className="color-picker-wrapper">
                <ColorPicker
                  label="Banner Color"
                  value={formikValues.lowerBannerColor}
                  onChange={(val) => handleInputChange('lowerBannerColor', val)}
                />
              </div>
              <div className="color-picker-wrapper">
                <ColorPicker
                  label="Text Color"
                  value={formikValues.lowerTextColor}
                  onChange={(val) => handleInputChange('lowerTextColor', val)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="status-overlay-section">
        <div className="status-overlay-config">
          <div className="section-title">
            Custom Status
          </div>
          <p>Add and remove custom status labels</p>
          <div className="custom-status">
            <div className="custom-status-inputs">
              {(formikValues.customStatus)?.map((customStatus, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <div className="custom-status-input" key={`status_${index}`}>
                  <CustomInput
                    value={customStatus.status_overlay || ''}
                    onChange={(value) => handleCustomStatusChange(value, index)}
                  />

                  <div
                    className="remove-code"
                    onClick={() => {
                      handleRemoveCustomStatusAtIndex('customStatus', index);
                    }}
                  >
                    <MinusRoundIcon color={ApplicationTheme.primaryColor} />
                  </div>
                </div>
              ))}
              <div
                className="add-code"
                onClick={() => {
                  const tempData = [...formikValues.customStatus];
                  tempData.push({
                    status_overlay: '', status_overlay_url: '', status_overlay_placement: 'Disabled', status_overlay_size: '25',
                  });
                  handleInputChange('customStatus', tempData);
                }}
              >
                <span>Add Custom Status</span>
                <PlusRoundIcon color={ApplicationTheme.primaryColor} />
              </div>
            </div>
          </div>
        </div>
        <div className="status-overlay-config">
          <div className="section-title">
            Status Overlay
          </div>
          <p>Configure image overlays based on the unit status or a custom status.</p>
          <div className="custom-status-overlay">
            <div className="custom-status">
              <div className="custom-status-inputs">
                <h6>Default</h6>
                <div className="custom-status-list">
                  {(formikValues.defaultStatus)?.map((formikValue, index) => (
                    <div
                      className={`custom-status-input ${formikValues.defaultStatus[index].status_overlay === formikValues.statusOverlay ? 'active' : ''}`}
                      key={`${formikValues.defaultStatus[index].status_overlay}_${formikValues.defaultStatus[index].id}`}
                      onClick={() => handleInputChange('statusOverlay', formikValues.defaultStatus[index].status_overlay)}
                    >
                      {formikValues.defaultStatus[index].status_overlay}
                    </div>
                  ))}
                </div>
              </div>
              <hr />
              <div className="custom-status-inputs">
                <h6>Custom</h6>
                <div className="custom-status-list">
                  {(formikValues.customStatus)?.map((formikValue, index) => (
                    <div
                      className={`custom-status-input ${formikValues.customStatus[index].status_overlay === formikValues.statusOverlay ? 'active' : ''}`}
                      key={`${formikValues.customStatus[index].status_overlay}_${formikValues.customStatus[index].id}`}
                      onClick={() => handleInputChange('statusOverlay', formikValues.customStatus[index].status_overlay)}
                    >
                      {formikValues.customStatus[index].status_overlay}
                    </div>
                  ))}
                </div>
              </div>
            </div>
            { formikValues.defaultStatus?.find((item: CustomStatusOverlayI) => item.status_overlay === formikValues.statusOverlay)?.status_overlay
                && (
                <DefaultStatusSection
                  formikValues={formikValues}
                  handleInputChange={handleInputChange}
                  handleStatusOverlayFileUpload={handleDefaultStatusOverlayFileUpload}
                  handleRemoveStatusOverlay={handleRemoveDefaultStatusOverlay}
                  defaultStatusOverlayOptions={defaultStatusOverlayOptions}
                  statusOverlayPlacementOptions={statusOverlayPlacementOptions}
                />
                )}

            { formikValues.customStatus?.find((item: CustomStatusOverlayI) => item.status_overlay === formikValues.statusOverlay)?.status_overlay
                && (
                <CustomStatusSection
                  formikValues={formikValues}
                  handleInputChange={handleInputChange}
                  handleStatusOverlayFileUpload={handleCustomStatusOverlayFileUpload}
                  handleRemoveStatusOverlay={handleRemoveCustomStatusOverlay}
                  customStatusOverlayOptions={customStatusOverlayOptions}
                  statusOverlayPlacementOptions={statusOverlayPlacementOptions}
                />
                )}
          </div>
        </div>
      </div>
      <div>
        <div className="save-side">
          <CustomButton
            variant="primary"
            wrapperClassname="save-btn"
            onClick={() => formik.handleSubmit()}
          >
            Save
          </CustomButton>
          <Whisper placement="right" controlId="save-id-hover" trigger="hover" speaker={saveTooltip}>
            <div>
              <InfoIco />
            </div>
          </Whisper>
        </div>
      </div>

    </Container>
  );
}

export default OverlaySectionForm;

export function InfoIco() {
  return (
    <StyledInfoIco className="info-ico">
      <InfoRoundIcon color={ApplicationTheme.secondaryColor} />
    </StyledInfoIco>
  );
}

const Container = styled.div`
  background-color: #ffffff;
  padding-left: 2.5%;
  padding-right: 2.5%;

  .default-overlay-config,
  .status-overlay-config {
    background-color: #f7f6f6;
    border-radius: 10px;
    margin-top: 40px;
    padding: 40px;
    text-align: left;
    @media screen and (max-width: 768px) {
      margin-top: 20px;
      padding: 20px;
    }
  }

  .logo-overlay-preview {
    max-width: 250px;
    margin-left: 51px;
    display: flex;
    position: relative;
    @media screen and (max-width: 768px) {
      margin: 20px auto 0;
      padding-right: 0;
    }

    .upper-text-overlay,
    .lower-text-overlay {
      position: absolute;
      text-align: center;
      font-size: 15px;
      width: 100%;
      left: 0;
      padding: 2px 0;
      overflow: hidden;
    }

    .upper-text-overlay {
      top: 0;
    }

    .lower-text-overlay {
      bottom: 0;
    }

    .logo-overlay-back {
      min-width: 250px;
      height: 160px;
    }

    &.ll {
      align-items: flex-end;
    }

    &.lr {
      justify-content: flex-end;
      align-items: flex-end;
    }

    &.ul {
      justify-content: flex-start;
      align-items: flex-start;
    }

    &.ur {
      justify-content: flex-end;
      align-items: flex-start;
    }

    &.disabled {
      .uploaded-logo {
        display: none;
      }
    }

    .uploaded-logo,
    .status-overlay-logo {
      position: absolute;
      z-index: 2;
    }
    
    .status-overlay-logo {
      padding: 5px;
      color: white;
      background-color: green
    }

    .red {
      background-color: red;
    }
    
    .orange {
      background-color: #ff9b41;
    }
  }

  .overlay-setting-section {
    text-align: start;
    display: flex;
    justify-content: space-between;

    @media screen and (max-width: 768px) {
      flex-direction: column;
    }

    .select-with-tooltip {
      display: flex;
      align-items: center;
      margin-top: 29px;

      .overlay-setting-select {
        width: 346px;
        @media screen and (max-width: 768px) {
          width: 280px;
        }

        label {
          font-size: 13px;
          white-space: nowrap;
        }
      }
    }
  }

  .save-side {
    position: fixed;
    bottom: 0;
    display: flex;
    left: 50%;
    @media screen and (max-width: 768px) {
      left: 27.5%;
      .info-ico {
        display: none;
      }
    }
    width: 250px;
    margin-bottom: 20px;

    div {
      align-items: center;
      display: flex;
    }

    .info-ico {
      margin-right: 9px;
    }

    .save-btn {
      width: 250px;
    }
  }

  .status-overlay-section {
    display: grid;
    grid-template-columns: 350px auto;
    gap: 20px;
    @media screen and (max-width: 1050px) {
      grid-template-columns: 100%;
      gap: 0;
    }
  }

  .size-input-wrap {
    width: 100%; 
      .size-input-symbol-wrap {
        .input-symbol {
          left: unset;
          right: 10px;
        }
        input {
          width: 100%;
          padding-right: 30px;
          text-align: center;
        }
      }
  }
  
  .status-overlay-settings {
    margin-top: 20px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    @media screen and (max-width: 1600px) {
      grid-template-columns: 100%;
    }
    .select-container {
      display: block;
      label {
        margin-bottom: 5px;
        display: block;
      }
    }
    .uploader {
      margin-top: 25px;
    }
    .logo-overlay-settings {
      display: flex;
      align-items: center;
      gap: 20px;
      .logo-placement-select {
        width: 100%;
        .select-wrap {
          height: 39px;
        }
      }
      .logo-size {
        display: block;
        width: 100%;
        font-weight: bold;
        label {
          margin-bottom: 5px;
          display: block;
        }
        input {
          font-weight: normal;
        }
      }
    }
    .logo-overlay-preview {
      margin: 0 auto;
    }
    @media screen and (max-width: 1600px) {
      .grid-space {
        display: none;
      }
    }
    .remove-status-overlay {
      cursor: pointer;
      font-size: 13px;
      color: ${(props) => props.theme.primaryColor};
      display: flex;
      align-items: center;
      margin: 0 auto;
      span {
        margin-right: 9px;
      }
      svg {
        width: 20px;
        height: 20px;
      }
    }
  }

  .logo-overlay {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 20px;
    text-align: start;
    margin-top: 30px;
    
    .logo-overlay-inputs .logo-size .size-input-wrap input#size {
      height: 39px !important;
    }

    .logo-overlay-inputs,
    .upper-text,
    .lower-text {
      background-color: white;
      padding: 30px;
      border-radius: 10px;
    }

    .color-picker-wrapper {
      margin-top: 15px;

      label {
        margin-bottom: 5px;
        display: block;
      }

      div:only-child {
        display: block;
      }

      input#html5colorpicker {
        width: 100%;
      }
    }
    
    @media screen and (max-width: 768px) {
      padding-right: 0;
      max-width: 85vw;
    }
    @media screen and (max-width: 1050px) {
      grid-template-columns: 100%;
      .logo-overlay-preview {
        margin-top: 20px;
      }
    }

    .select-container {
      display: block;
      label {
        margin-bottom: 5px;
        display: block;
      }
    }

    .logo-overlay-inputs {
      .logo-placement-select {
        width: 100%;
        margin-top: 19px;
        justify-content: flex-start;

        label {
          font-size: 13px;
          white-space: nowrap;
          font-weight: normal;
          margin-right: 9px;
        }
      }

      .logo-size {
        width: 100%;
        margin-top: 18px;
        display: block;

        label {
          font-size: 13px;
        }

        .size-input-wrap {
          margin-top: 5px;
          margin-left: 0;
          justify-content: start;
          @media screen and (max-width: 768px) {
            .input-symbol {
              left: 25px;
              top: 8px;
            }
          }

          input {
            height: 34px;
          }

        }
      }
    }
    
    .logo-overlay-settings {
      display: flex;
      justify-content: space-between;
      gap: 20px;
      @media screen and (max-width: 768px) {
        display: block;
      }
    }
  }

  .uploader {
    margin-top: 40px;

    .dropzone {
      margin-left: 0;
      min-width: 100%;
      width: 100%;
      max-width: 100%;
      height: 60px;
    }
  }

  .text-overlay-inputs {
    .text-to-display-select {
      .select-container {
        margin-top: 20px;
      }
    }
  }
   
  .custom-status-overlay {
    display: grid;
    grid-template-columns: 250px 3fr;
    gap: 20px;

    @media screen and (max-width: 1050px) {
      grid-template-columns: 100%;
      gap: 0;
    }
  }
  
  .custom-status {
    text-align: start;
    background-color: white;
    padding: 30px;
    border-radius: 10px;
    margin-top: 20px;
    
    @media screen and (max-width: 768px) {
      margin-top: 22px;
      padding-right: 20px;
      max-width: 85vw;
    }
    .custom-status-inputs {
      .custom-status-list {
        margin-top: 10px;
        padding-left: 15px;
        div {
          cursor: pointer;
        }
        div.active,
        div:hover {
          color: #ff9b41;
        }
      }
      .custom-status-input {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        
        div:first-child {
          width: 100%;
        }
      }
      .symbol-input-wrapper {
        width: 100%;
        input {
          height: 36px;
          width: 100%;
          max-width: 100%;
        }
      }
      .add-code {
        cursor: pointer;
        font-size: 13px;
        color: ${(props) => props.theme.primaryColor};
        display: flex;
        align-items: center;
        justify-content: space-between;
        span {
          margin-right: 9px;
        }
        svg {
          width: 20px;
          height: 20px;
        }
      }
      .remove-code {
        cursor: pointer;
        font-size: 13px;
        color: ${(props) => props.theme.primaryColor};
        margin-left: 5px;
        span {
          margin-left: 9px;
        }
        svg {
          width: 20px;
          height: 20px;
        }
      }
    }
  }
  .section-title {
    font-size: 25px;
    color: ${(props) => props.theme.primaryColor};
  }
  padding-bottom: 82px;
`;

const StyledInfoIco = styled.div`
    margin-left: 9px;
    svg {
      width: 20px;
      height: 20px;
    }
`;
const StyledTooltip = styled(Tooltip)`
  max-width: 241px;
  padding: 10px;
  text-align: center;
  font-size: 10px;
  background-color: ${(props) => props.theme.primaryBGColor};
  border-radius: 3px;
  border: 1px solid #f7f6f6;
  color: ${(props) => props.theme.primaryTextColor};
  .highlighted {
    color: ${(props) => props.theme.primaryColor};
    font-weight: bold;
    line-height: 1.3rem;
  }
`;
