import PlusRoundIcon from '@rsuite/icons/PlusRound';
import React, { useCallback, useEffect, useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { FileRejection, useDropzone } from 'react-dropzone';
import styled from 'styled-components';
import { saveAs } from 'file-saver';
import { useParams } from 'react-router-dom';
import { Loader } from 'rsuite';
import heic2any from 'heic2any';
import { useAppMessageContext } from '../../appMessagesContext';
import removeBin from '../../assets/remove-bin.png';
import { ApplicationTheme } from '../../globalStyles';
import { getLogoPosition, niceBytes } from '../../helpers';
import {
  FileFromResponse,
  UnitUserOverlaySetting,
} from '../../store/unit/actions';
import CustomButton from '../Buttons/CustomButton';
import DeleteModal from '../DeleteModal';
import DndCard from '../DnDCard';
import DownloadSvg from '../SvgElements/DownloadSvg';
import imagePlaceholder from '../../assets/image_placeholder.png';
import { UnitRequests } from '../../store/unit/requests';

export interface UploadedFile extends File {
  url: string
  id: number
  isFile?: boolean
  thumb_uri?: string | null
}
export type RenderImageT = {
  url: string
  name: string
  id: number
  isFile?: boolean
  thumb_uri?: string | null
} | UploadedFile
export type UploaderOverlay = UnitUserOverlaySetting & {
  setting: string
}
interface FileUploaderProps {
  title?: string
  subtitle?: string
  fileType?: 'image/*' | 'application/pdf' | 'application/*' | '.csv'
  fileExtensions?: string[]
  onChange?: (files: UploadedFile[]) => void
  onDeleteAll?: () => void
  onPreviewChange?: (files: RenderImageT[]) => void
  initialPreviewFiles?: RenderImageT[]
  initialFiles?: UploadedFile[]
  filesFromResponse?: FileFromResponse[]
  hidePreview?: boolean
  multiple?: boolean
  customDragZoneContent?: JSX.Element
  fileUrls?: string[]
  maxSize?: number
  maxFiles?: number
  downloadAllBtn?: boolean
  overlaySettings?: UploaderOverlay
  acceptFiles?: {
    [key: string]: string[]
  }
}
type ModalObjType = {file: File | FileFromResponse | RenderImageT, index: number, type: 'single'} | null | {type: 'all'}

function FileUploader({
  title, subtitle, fileType, fileExtensions, onDeleteAll, maxSize, onChange, onPreviewChange, initialPreviewFiles, initialFiles, hidePreview, multiple = true, fileUrls, filesFromResponse, customDragZoneContent, downloadAllBtn, overlaySettings, acceptFiles,
  maxFiles = 50,
}: FileUploaderProps) {
  const { showMessage } = useAppMessageContext();
  const [showModel, setShowModal] = useState<ModalObjType>(null);
  const [files, setFiles] = useState<UploadedFile[]>(initialFiles || []);
  const [filesToPreview, setFilesToPreview] = useState<RenderImageT[]>(initialPreviewFiles || []);
  useEffect(() => {
    const temp = filesFromResponse || [];
    if (temp.length) {
      setFilesToPreview(temp?.map((file) => (file?.isFile ? file : ({
        id: file.id,
        name: file.name,
        url: file.url,
        thumb_uri: file.thumb_uri,
      }))));
    }
  }, [filesFromResponse?.length]);
  const params = useParams<{ id: string }>();
  const { getRootProps, getInputProps } = useDropzone({
    accept: acceptFiles || {
      ...{ ...(fileType && { [fileType]: fileExtensions || [] }) },
    },
    maxSize,
    multiple,
    maxFiles: maxFiles - (filesFromResponse?.length || 0),
    onDrop: async (acceptedFiles: File[]) => {
      const convertedFiles = await Promise.all(acceptedFiles.map(async (file) => {
        if (file.type === 'image/heic' || file.type === 'image/heif') {
          const convertedFile = await heic2any({
            blob: file,
            toType: 'image/jpeg',
            quality: 0.8,
          });
          const blob = Array.isArray(convertedFile) ? convertedFile[0] : convertedFile;
          return new File([blob], file.name, { type: 'image/jpeg' });
        }
        return file;
      }));

      const renamedAcceptedFiles = convertedFiles.map((file) => {
        const renameFile = (originalFile: File, newName: string) => new File([originalFile], newName, { type: originalFile.type, lastModified: originalFile.lastModified });
        return renameFile(file, `${file.name.slice(0, file.name.lastIndexOf('.'))}-${Math.random().toString(36).slice(2, 7)}${file.name.slice(file.name.lastIndexOf('.'))}`);
      });
      if (multiple) {
        const names = new Set(files.map((file) => file.name));
        const merged = [...files, ...renamedAcceptedFiles.filter((file: File) => !names.has(file.name)),
        ];
        const resFiles = merged.map((file: File, index: number) => Object.assign(file, {
          url: URL.createObjectURL(file),
          id: index,
        }));
        setFiles(resFiles);

        const namesPreview = new Set(filesToPreview.map((file) => file.name));
        const filtered = [...renamedAcceptedFiles.filter((file: File) => !namesPreview.has(file.name))];
        const acceptedToPreview = filtered.map((file: File, index: number) => Object.assign(file, {
          url: URL.createObjectURL(file),
          id: index,
          isFile: true,
        }));
        const mergedPreview = [...filesToPreview, ...acceptedToPreview];
        setFilesToPreview(mergedPreview);
      } else {
        setFiles(renamedAcceptedFiles.map((file: File, index: number) => Object.assign(file, {
          url: URL.createObjectURL(file),
          id: index,
        })));
      }
    },
    onDropRejected: (fileRejections: FileRejection[]) => {
      if (fileRejections[0].errors.some((err) => err.code === 'file-too-large')) {
        showMessage({ type: 'error', message: `File is larger than ${niceBytes(Number(maxSize))}` });
      } else if (fileRejections[0].errors.some((err) => err.message === 'Too many files')) {
        showMessage({ type: 'error', message: `Maximum ${maxFiles} files per unit` });
      } else {
        showMessage({ type: 'error', message: `Incorrect file extension${fileExtensions?.length ? `, please select one of the following${fileExtensions?.join(' ')}` : ''}` });
      }
    },
  });
  const handleFileRemove = (removeObj: ModalObjType) => {
    if (removeObj?.type === 'single') {
      const tempFiles = [...files];
      tempFiles.splice(removeObj.index, 1);
      const tempPreviewFiles = [...filesToPreview];
      tempPreviewFiles.splice(removeObj.index, 1);
      setFiles([...tempFiles]);
      setFilesToPreview([...tempPreviewFiles]);
      setShowModal(null);
      showMessage({ type: 'success', message: `${fileType === 'image/*' ? 'Image' : 'File'} Removed successfully` });
      if (!tempPreviewFiles?.length) {
        setFilesToPreview([]);
        onDeleteAll?.();
      }
    } else {
      setFiles([]);
      setShowModal(null);
      showMessage({ type: 'success', message: `All ${fileType === 'image/*' ? 'Images' : 'Files'} Removed successfully` });
      setFilesToPreview([]);
      onDeleteAll?.();
    }
  };

  useEffect(
    () => () => files.forEach((file: UploadedFile) => URL.revokeObjectURL(file?.url)),
    [],
  );
  function moveElement(array: RenderImageT[], fromIndex: number, toIndex: number) {
    const arrayCopy = [...array];
    const element = arrayCopy.splice(fromIndex, 1)[0];
    arrayCopy.splice(toIndex, 0, element);
    return arrayCopy;
  }

  const moveCard = useCallback((dragIndex: number, hoverIndex: number) => {
    const result = moveElement(filesToPreview, dragIndex, hoverIndex);
    setFilesToPreview(result);
  }, [filesToPreview]);

  const renderThumbs = useCallback((file: RenderImageT, index: number) => (
    <DndCard<UploadedFile>
      key={file.name + index}
      index={index}
      id={file.id}
      moveCard={moveCard}
    >
      <div
        className="thumb-container"
        key={file.name}
      >
        <div className={`thumb-wrapper ${getLogoPosition(String(overlaySettings?.logo_placement))}`}>
          <div className="primary-image">
            Primary
          </div>
          <div className="remove-btn" onClick={() => setShowModal({ file, index, type: 'single' })}>
            <img src={removeBin} alt="remove" />
          </div>
          {(overlaySettings?.upper_text && overlaySettings?.upper_text !== 'Disabled')
              && ((overlaySettings.setting === 'Primary' && index === 0) || overlaySettings.setting === 'All')
              && (
              <div
                className="upper-text-overlay"
                style={{
                  color: overlaySettings?.upper_text_color || '',
                  backgroundColor: overlaySettings?.upper_background_color || '',
                }}
              >
                {overlaySettings?.upper_text}
              </div>
              )}
          {(overlaySettings?.lower_text && overlaySettings?.lower_text !== 'Disabled')
              && ((overlaySettings.setting === 'Primary' && index === 0) || overlaySettings.setting === 'All')
              && (
              <div
                className="lower-text-overlay"
                style={{
                  color: overlaySettings?.lower_text_color || '',
                  backgroundColor: overlaySettings?.lower_background_color || '',
                }}
              >
                {overlaySettings?.lower_text}
              </div>
              )}
          <img
            alt="preview"
            src={file?.thumb_uri ? file?.thumb_uri : file.url}
            className="thumb-image"
            onError={({ currentTarget }) => {
              // eslint-disable-next-line no-param-reassign
              currentTarget.onerror = null; // prevents looping
              if (currentTarget?.src !== file.url) {
                // eslint-disable-next-line no-param-reassign
                currentTarget.src = file.url;
              } else {
                // eslint-disable-next-line no-param-reassign
                currentTarget.src = imagePlaceholder;
              }
            }}
          />
          {overlaySettings?.logo_url && overlaySettings?.logo_placement !== 'Disabled'
              && ((overlaySettings.setting === 'Primary' && index === 0) || overlaySettings.setting === 'All')
              && (
              <img
                alt="preview"
                src={overlaySettings?.logo_url}
                className="overlay-image"
                style={{
                  maxWidth: `${overlaySettings?.logo_size}%`, // Incorporates logoSize
                  position: 'absolute',
                  zIndex: 2,
                }}
              />
              )}
        </div>
      </div>
    </DndCard>
  ), [files, moveCard, overlaySettings]);

  useEffect(() => {
    onChange?.(files);
  }, [files]);
  useEffect(() => {
    onPreviewChange?.(filesToPreview);
  }, [filesToPreview]);

  useEffect(() => {
    if (fileUrls?.length) {
      Promise.all<Response>(
        fileUrls.map((url) => fetch(url)),
      )
        .then((results) => {
          const filesFromUrl: UploadedFile[] = [];
          results.forEach(async (res: Response) => {
            const spitedUrl = res.url.split('/');
            const fileName = spitedUrl[spitedUrl.length - 1];
            await res.arrayBuffer().then((buf: ArrayBuffer) => {
              const resultFile = new File([buf], fileName, { type: fileType || 'image/jpeg' });
              filesFromUrl.push(Object.assign(resultFile, {
                url: URL.createObjectURL(resultFile),
                id: filesFromUrl.length,
              }));
            });
            setFiles(filesFromUrl);
          });
        });
    }
  }, [fileUrls?.length]);
  const modalText = () => (
    <div>
      <h3>
        Delete
      </h3>
      <div className="text">
        Are you sure you would like
        <br />
        {showModel?.type === 'all' ? `to Delete All ${fileType === 'image/*' ? 'Images' : 'Files'}` : `to Delete  this ${fileType === 'image/*' ? 'image' : 'file'}?`}
      </div>
    </div>
  );

  const [isLoading, setIsLoading] = useState(false);
  const downloadAll = async () => {
    setIsLoading(true);
    const response = await UnitRequests.GetItemImagesZipFile(params?.id);
    if (response.status === 200) {
      setIsLoading(false);
      const zipFileUrl = response.data;
      saveAs(response.data, zipFileUrl.substring(zipFileUrl.lastIndexOf('/') + 1));
    }
    setIsLoading(false);
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <DragZone className="container">
        {!hidePreview
              && (
              <aside className="preview-container">
                {fileType === 'image/*'
                  ? filesToPreview.map((file, i) => renderThumbs(file, i))
                  : (
                    <div className="file-names-container">
                      {filesToPreview.map((file, index) => (
                        <div key={file.name} className="file-names">
                          <a href={file.url} target="_blank" rel="noreferrer">{file.name}</a>
                          <span onClick={() => setShowModal({ file, index, type: 'single' })}>
                            <img src={removeBin} alt="remove" />
                          </span>
                        </div>
                      ))}
                    </div>
                  )}
              </aside>
              )}
        {((files.length > 1 || filesToPreview?.length > 1) && (maxFiles && maxFiles > 1))
              && (
              <div className="delete-all" onClick={() => setShowModal({ type: 'all' })}>
                <img src={removeBin} alt="remove" />
                <span>
                  Delete All
                  {fileType === 'image/*' ? ' Images' : ' Files'}
                </span>
              </div>
              )}
        <div className="buttons">
          { filesToPreview?.length < maxFiles && (
          <div {...getRootProps({ className: 'dropzone' })}>
            <input {...getInputProps()} />
            {customDragZoneContent
          || (
          <div className="drag-zone-title-container">
            <PlusRoundIcon color={ApplicationTheme.primaryColor} />
            <span className="title">{title}</span>
            <span className="sub-title">{subtitle}</span>
          </div>
          )}
          </div>
          )}
          {downloadAllBtn
          && (
          <CustomButton variant="outlined-box" className={`download-img-btn ${isLoading ? 'transform-loading' : ''}`} wrapperClassname="outlined-wrap" onClick={downloadAll}>
            {isLoading ? (
              <div>
                <Loader center content="Generating File..." />
              </div>
            ) : (
              <div>
                <DownloadSvg fill={ApplicationTheme.primaryColor} stroke={ApplicationTheme.primaryColor} />
                <span className="text">
                  Download All
                </span>
              </div>
            )}
          </CustomButton>
          )}
        </div>
        <DeleteModal<ModalObjType>
          openObj={showModel}
          onApproveClose={(openObj) => handleFileRemove(openObj)}
          onClose={() => setShowModal(null)}
          mainContent={modalText()}
        />
      </DragZone>
    </DndProvider>
  );
}

export default FileUploader;

const DragZone = styled.section`
  .dropzone {
    min-width: 322px;
    max-width: 322px;
    border: 0.5px solid ${(props) => props.theme.primaryColor};
    border-radius: 4px;
    padding: 8px;
    height: 50px;
    cursor: pointer;
    @media screen and (max-width: 768px) {
      margin-left: 10px;
    }
    @media screen and (min-width: 768px) {
      min-width: 346px;
      max-width: 364px;
      margin-top: 0;
    }
    transition: all ease 0.2s;
    :hover {
      transform: scale(1.01);
    }
    .drag-zone-title-container {
      display: flex;
      align-items: center;
      height: 100%;
      margin-left: 3px;
      .title {
        margin-left: 8px;
        font-size: 13px;
        color: ${(props) => props.theme.primaryColor};
      }
      .sub-title {
        font-size: 10px;
        font-style: italic;
        color: ${(props) => props.theme.secondaryColor};
        margin-left: 33px;
      }
      svg {
        width: 20px;
        height: 20px;
      }
    }
  }
  .delete-all {
    color: ${(props) => props.theme.primaryColor};
    display: flex;
    align-items: center;
    justify-content: start;
    cursor: pointer;
    margin-bottom: 38px;
    img {
      width: 22px;
      height: 22px;
    }
  }
  .preview-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 16px;
    .primary-image {
      display: none;
      position: absolute;
      background-color: ${(props) => props.theme.primaryColor};
      font-size: 12px;
      border-radius: 1px;
      padding: 8px;
      color: #fff;
      z-index: 3;
    }
    >:first-child {
      .primary-image {
        display: block;
      }
    }
    .file-names-container {
      display: flex;
      flex-direction: column;
      align-items: start;
      .file-names {
        cursor: pointer;
        text-decoration: underline;
        margin-bottom: 8px;
        a {
          color: #575757;
        }
        img {
          width: 18px;
          height: 18px;
        }
      }
    }
  }
  .thumb-container {
    margin-right: 8px;
    padding: 4px;
    .thumb-wrapper {
      display: flex;
      min-width: 0;
      overflow: hidden;
      height: 117px;
      width: 144px;
      border-radius: 4px;
      position: relative;
      .overlay-image {
        width: auto;
        z-index: 2;
        position: absolute;
        object-fit: initial;
      }
      .upper-text-overlay,
      .lower-text-overlay {
        overflow: hidden;
        position: absolute;
        text-align: center;
        font-size: 15px;
        width: 100%;
        left: 0;
        padding-top: 2px;
        z-index: 1;
        max-height: 22px;
      }
      .upper-text-overlay {
        top: 0;
      }
      .lower-text-overlay {
        bottom: 0;
      }
      &.ll {
        align-items: flex-end;
      }
      &.lr {
        justify-content: flex-end;
        align-items: flex-end;
      }
      &.ul {
        justify-content: flex-start;
        align-items: flex-start;
      }
      &.ur {
        justify-content: flex-end;
        align-items: flex-start;
      }
      .primary-image {
        top: 0;
        z-index: 20;
        left: 0;
      }
      .remove-btn {
        visibility: hidden;
        display: flex;
        border: 1px solid ${(props) => props.theme.primaryColor};
        border-radius: 50%;
        background-color: #fff;
        width: 16px;
        height: 16px;
        position: absolute;
        top: 4px;
        right: 6px;
        cursor: pointer;
        z-index: 10;
      }
      transition: all ease 0.2s;
      :hover {
        .remove-btn {
          visibility: visible;
        }
      }
    }
    .thumb-wrapper img {
      max-height: 100%;
      &.thumb-image {
        height: 100%;
      }
      width: 100%;
      object-fit: cover;
    }
  }
  .outlined-wrap {
    max-width: 160px;
  }
  .buttons {
    display: flex;
    @media screen and (max-width: 768px) {
      flex-direction: column;
      .outlined-wrap {
        display: flex;
        margin-top: 15px;
        justify-content: flex-start;
      }
    }
  }
  .download-img-btn {
    margin-left: 20px;
    width: 160px;
    @media screen and (max-width: 768px) {
      margin-left: 10px;
    }
    height: 50px;
    border-radius: 4px;
    border-color: ${(props) => props.theme.primaryColor};
    color: ${(props) => props.theme.primaryColor};
    transition: all ease 0.2s;
    :hover {
      transform: scale(1.01);
      font-weight: normal;
      border: 1px solid;
    }
    .text {
      margin-left: 10px;
    }
  }
  .transform-loading {
    transform: scale(1.01);
  }
`;
