import MinusRoundIcon from '@rsuite/icons/MinusRound';
import React from 'react';
import { statusOptions } from '../../../UnitPage/Sections/Specifics/data';
import CustomSelect, { SelectOption } from '../../../../components/inputs/CustomSelect';
import ControlledNumericInput from '../../../../components/inputs/ControlledNumericInput';
import FileUploader, { UploadedFile } from '../../../../components/FileUploader';
import { getLogoPosition } from '../../../../helpers';
import logoOverlayPlaceholder from '../../../../assets/logoOverlayPlaceholder.png';
import { ApplicationTheme } from '../../../../globalStyles';
import { CustomStatusOverlayI } from '../../../../store/settings/requests';

function CustomStatusSection(
  {
    formikValues,
    handleInputChange,
    handleStatusOverlayFileUpload,
    handleRemoveStatusOverlay,
    customStatusOverlayOptions,
    statusOverlayPlacementOptions,
  }: {
        formikValues: any,
        handleInputChange: (name: string, value: any) => void,
        handleStatusOverlayFileUpload: (file: UploadedFile[]) => void,
        handleRemoveStatusOverlay: () => void,
        customStatusOverlayOptions: any,
        statusOverlayPlacementOptions: any
    },
) {
  return (
    <div className="custom-status">
      <div className="section-title">
        {statusOptions.map((option) => option.value).includes(formikValues.statusOverlay)
          ? `Default Status: ${formikValues.statusOverlay}`
          : `Custom Status: ${customStatusOverlayOptions.find((option: SelectOption) => option.value === formikValues.statusOverlay)?.label || formikValues.statusOverlay}`}
      </div>
      <div className="status-overlay-settings">
        <div className="logo-overlay-inputs">
          <div className="logo-overlay-settings">
            <div className="logo-placement-select">
              {' '}
              <CustomSelect
                label="Placement"
                options={statusOverlayPlacementOptions}
                value={formikValues.customStatus?.find((item: CustomStatusOverlayI) => item.status_overlay === formikValues.statusOverlay)?.status_overlay_placement || 'Disabled'}
                onChange={(val) => handleInputChange('customStatus', formikValues.customStatus?.map((item: CustomStatusOverlayI) => (item.status_overlay === formikValues.statusOverlay
                  ? { ...item, status_overlay_placement: val?.label }
                  : item)))}
              />
            </div>
            <ControlledNumericInput
              wrapperClass="logo-size"
              label="Status Overlay Size"
              value={formikValues.customStatus?.find((item: CustomStatusOverlayI) => item.status_overlay === formikValues.statusOverlay)?.status_overlay_size || '25'}
              onChange={(value) => handleInputChange('customStatus', formikValues.customStatus?.map((item: CustomStatusOverlayI) => (item.status_overlay === formikValues.statusOverlay
                ? { ...item, status_overlay_size: value }
                : item)))}
            />
          </div>
          <div className="uploader">
            <FileUploader
              title="Add Custom Label"
              subtitle="or drag and drop an image within this box"
              acceptFiles={{
                'image/jpeg': [],
                'image/png': [],
                'image/gif': [],
              }}
              onChange={handleStatusOverlayFileUpload}
              initialFiles={formikValues.customStatus?.find((item: CustomStatusOverlayI) => item.status_overlay === formikValues.statusOverlay)?.status_overlay_file ? [formikValues.customStatus?.find((item: CustomStatusOverlayI) => item.status_overlay === formikValues.statusOverlay)?.status_overlay_file as UploadedFile] : []}
              filesFromResponse={[{
                id: 1,
                name: '',
                order: 0,
                size: 1,
                type: '',
                url: formikValues.customStatus?.find((item: CustomStatusOverlayI) => item.status_overlay === formikValues.statusOverlay)?.status_overlay_url || '',
              }]}
              hidePreview
              multiple={false}
            />
          </div>
        </div>
        <div
          className={`logo-overlay-preview ${getLogoPosition(String(formikValues.customStatus?.find((item: CustomStatusOverlayI) => item.status_overlay === formikValues.statusOverlay)?.status_overlay_placement))}`}
        >
          {(formikValues.customStatus?.find((item: CustomStatusOverlayI) => item.status_overlay === formikValues.statusOverlay)?.status_overlay_file || formikValues.customStatus?.find((item: CustomStatusOverlayI) => item.status_overlay === formikValues.statusOverlay)?.status_overlay_url)
                      && (
                      <img
                        className="uploaded-logo"
                        alt="logo"
                        src={formikValues.customStatus?.find((item: CustomStatusOverlayI) => item.status_overlay === formikValues.statusOverlay)?.status_overlay_file?.url || formikValues.customStatus?.find((item: CustomStatusOverlayI) => item.status_overlay === formikValues.statusOverlay)?.status_overlay_url || ''}
                        width={`${formikValues.customStatus?.find((item: CustomStatusOverlayI) => item.status_overlay === formikValues.statusOverlay)?.status_overlay_size}%`}
                      />
                      )}
          {((!formikValues.customStatus?.find((item: CustomStatusOverlayI) => item.status_overlay === formikValues.statusOverlay)?.status_overlay_file && !formikValues.customStatus?.find((item: CustomStatusOverlayI) => item.status_overlay === formikValues.statusOverlay)?.status_overlay_url) && formikValues.customStatus?.find((item: CustomStatusOverlayI) => item.status_overlay === formikValues.statusOverlay)?.status_overlay_placement !== 'Disabled')
                      && (
                      <div
                        className={`status-overlay-logo ${formikValues.customStatus?.find((item: CustomStatusOverlayI) => item.status_overlay === formikValues.statusOverlay)?.status_overlay === 'Sold' ? 'red' : ''}`}
                      >
                        {formikValues.customStatus?.find((item: CustomStatusOverlayI) => item.status_overlay === formikValues.statusOverlay)?.status_overlay || formikValues.statusOverlay}
                      </div>
                      )}
          <img src={logoOverlayPlaceholder} alt="logo-overlay" className="logo-overlay-back" />
        </div>
        <div className="grid-space" />
        <div
          className="remove-status-overlay"
          onClick={handleRemoveStatusOverlay}
        >
          <span>Remove Status Overlay</span>
          <MinusRoundIcon color={ApplicationTheme.primaryColor} />
        </div>
      </div>
    </div>
  );
}

export default CustomStatusSection;
