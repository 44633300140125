import { CustomInputProps } from '../../../../components/inputs/CustomInput';
import { SelectUnknownOption } from '../../../../components/inputs/CustomSelect';
import { formSelectOptions } from '../../../../helpers';
import { InventoryTypeInputT } from '../Specifics/data';

export type InputsArrType = {
  label: string
  required: boolean
  type: 'select' | 'select-with-button';
  options: SelectUnknownOption[]
  key: InventoryTypeInputT['key']
  pattern?: CustomInputProps['pattern']
} | {
  label: string
  required: boolean
  type: 'input' | 'building-title';
  pattern?: CustomInputProps['pattern']
  key: InventoryTypeInputT['key']
};

export const defaultBlankOptions = formSelectOptions(['']);

export const isRentalOptions = formSelectOptions(['Yes', 'No']);

export const livingQuartersOptions = formSelectOptions(['Yes', 'No']);

export const mangerOptions = formSelectOptions(['Yes', 'No']);

export const isTiltOptions = formSelectOptions(['Yes', 'No']);

export const mockedAttributes = [
  {
    id: 1,
    name: 'Building',
    attributes: [
      {
        id: 30,
        name: 'external_link',
        alias: 'External Link',
        options: null,
      },
      {
        id: 39,
        name: 'is_rental',
        alias: 'Is Rental',
        options: [
          'Yes',
          'No',
        ],
        default: '',
      },
      {
        id: 64,
        name: 'min_height',
        alias: 'Min Height',
        options: null,
      },
      {
        id: 65,
        name: 'min_width',
        alias: 'Min Width',
        options: null,
      },
      {
        id: 66,
        name: 'min_length',
        alias: 'Min Length',
        options: null,
      },
      {
        id: 67,
        name: 'overall_length',
        alias: 'Overall Length',
        options: null,
      },
      {
        id: 69,
        name: 'daily_rate',
        alias: 'Daily Rate',
        options: null,
        pattern: 'decinumeric',
      },
      {
        id: 70,
        name: 'weekly_rate',
        alias: 'Weekly Rate',
        options: null,
        pattern: 'decinumeric',
      },
      {
        id: 71,
        name: 'monthly_rate',
        alias: 'Monthly Rate',
        options: null,
        pattern: 'decinumeric',
      },
    ],
  },
  {
    id: 2,
    name: 'Equipment',
    attributes: [
      {
        id: 30,
        name: 'external_link',
        alias: 'External Link',
        options: null,
      },
      {
        id: 39,
        name: 'is_rental',
        alias: 'Is Rental',
        options: [
          'Yes',
          'No',
        ],
        default: '',
      },
      {
        id: 64,
        name: 'min_height',
        alias: 'Min Height',
        options: null,
      },
      {
        id: 65,
        name: 'min_width',
        alias: 'Min Width',
        options: null,
      },
      {
        id: 66,
        name: 'min_length',
        alias: 'Min Length',
        options: null,
      },
      {
        id: 67,
        name: 'overall_length',
        alias: 'Overall Length',
        options: null,
      },
      {
        id: 69,
        name: 'daily_rate',
        alias: 'Daily Rate',
        options: null,
        pattern: 'decinumeric',
      },
      {
        id: 70,
        name: 'weekly_rate',
        alias: 'Weekly Rate',
        options: null,
        pattern: 'decinumeric',
      },
      {
        id: 71,
        name: 'monthly_rate',
        alias: 'Monthly Rate',
        options: null,
        pattern: 'decinumeric',
      },
    ],
  },
  {
    id: 3,
    name: 'Horse Trailer',
    attributes: [
      {
        id: 8,
        name: 'axles',
        alias: 'Axles',
        options: [
          '1',
          '2',
          '3+',
        ],
      },
      {
        id: 16,
        name: 'color',
        alias: 'Color',
        options: [
          'Black',
          'White',
          'Gray',
          'Brown',
          'Blue',
          'Yellow',
          'Red',
          'Pink',
          'Purple',
          'Green',
          'Orange',
          'Silver',
        ],
      },
      {
        id: 17,
        name: 'configuration',
        alias: 'Configuration',
        options: [
          'Head to Head',
          'Reverse Slant',
          'Slant',
          'Straight',
        ],
      },
      {
        id: 18,
        name: 'construction',
        alias: 'Construction',
        options: [
          'Aluminum',
          'Composite',
          'Fiberglass',
          'Galvanized',
          'Hypalon',
          'Other',
        ],
      },
      {
        id: 19,
        name: 'conversion_company',
        alias: 'Conversion Company',
        options: [
          'Bunkhouse of America',
          'Compass Conversions',
          'Outback Conversions',
          'Outlaw Conversions',
          'Trail Boss Conversions',
          'West Texas Conversions',
        ],
      },
      {
        id: 20,
        name: 'custom_conversion_company',
        alias: 'Custom Conversion Company',
        options: null,
      },
      {
        id: 30,
        name: 'external_link',
        alias: 'External Link',
        options: null,
      },
      {
        id: 39,
        name: 'is_rental',
        alias: 'Is Rental',
        options: [
          'Yes',
          'No',
        ],
        default: '',
      },
      {
        id: 41,
        name: 'living_quarters',
        alias: 'Living Quarters',
        options: [
          'Yes',
          'No',
        ],
        default: '',
      },
      {
        id: 42,
        name: 'manger',
        alias: 'Manger',
        options: [
          'Yes',
          'No',
        ],
        default: '',
      },
      {
        id: 43,
        name: 'midtack',
        alias: 'Midtack',
        options: null,
      },
      {
        id: 48,
        name: 'pull_type',
        alias: 'Pull Type',
        options: [
          'Fifth Wheel',
          'Bumper',
          'Gooseneck',
          'Pintle',
          'Weight Distribution',
        ],
      },
      {
        id: 49,
        name: 'ramps',
        alias: 'Ramps',
        options: null,
      },
      {
        id: 53,
        name: 'shortwall_length',
        alias: 'Shortwall Length',
        options: null,
      },
      {
        id: 55,
        name: 'slideouts',
        alias: 'Slideouts',
        options: [
          '1',
          '2',
          '3',
          '4+',
        ],
      },
      {
        id: 56,
        name: 'stalls',
        alias: 'Stalls',
        options: [
          '1',
          '2',
          '3',
          '4',
          '5',
          '6+',
        ],
      },
      {
        id: 64,
        name: 'min_height',
        alias: 'Min Height',
        options: null,
      },
      {
        id: 65,
        name: 'min_width',
        alias: 'Min Width',
        options: null,
      },
      {
        id: 66,
        name: 'min_length',
        alias: 'Min Length',
        options: null,
      },
      {
        id: 67,
        name: 'overall_length',
        alias: 'Overall Length',
        options: null,
      },
      {
        id: 69,
        name: 'daily_rate',
        alias: 'Daily Rate',
        options: null,
        pattern: 'decinumeric',
      },
      {
        id: 70,
        name: 'weekly_rate',
        alias: 'Weekly Rate',
        options: null,
        pattern: 'decinumeric',
      },
      {
        id: 71,
        name: 'monthly_rate',
        alias: 'Monthly Rate',
        options: null,
        pattern: 'decinumeric',
      },
    ],
  },
  {
    id: 4,
    name: 'Recreational Vehicle',
    attributes: [
      {
        id: 3,
        name: 'ac_btu',
        alias: 'AC BTU',
        options: null,
      },
      {
        id: 4,
        name: 'air_conditioners',
        alias: 'Air Conditioners',
        options: null,
      },
      {
        id: 5,
        name: 'available_beds',
        alias: 'Available Beds',
        options: null,
      },
      {
        id: 6,
        name: 'awning_size',
        alias: 'Awning Size',
        options: null,
      },
      {
        id: 7,
        name: 'axle_weight',
        alias: 'Axle Weight',
        options: null,
      },
      {
        id: 9,
        name: 'black_water_capacity',
        alias: 'Black Water Capacity',
        options: null,
        pattern: 'decinumeric',
      },
      {
        id: 10,
        name: 'fresh_water_capacity',
        alias: 'Fresh Water Capacity',
        options: null,
        pattern: 'decinumeric',
      },
      {
        id: 11,
        name: 'gray_water_capacity',
        alias: 'Gray Water Capacity',
        options: null,
        pattern: 'decinumeric',
      },
      {
        id: 14,
        name: 'cargo_weight',
        alias: 'Cargo Weight',
        options: null,
      },
      {
        id: 25,
        name: 'dry_weight',
        alias: 'Dry Weight',
        options: null,
      },
      {
        id: 26,
        name: 'electrical_service',
        alias: 'Electrical Service',
        options: null,
      },
      {
        id: 30,
        name: 'external_link',
        alias: 'External Link',
        options: null,
      },
      {
        id: 32,
        name: 'fuel_type',
        alias: 'Fuel Type',
        options: [
          'Diesel',
          'Electric',
          'FlexFuel',
          'Gas',
          'Hybrid',
          'Lithium',
        ],
      },
      {
        id: 33,
        name: 'furnace_btu',
        alias: 'Furnace BTU',
        options: null,
      },
      {
        id: 35,
        name: 'hitch_weight',
        alias: 'Hitch Weight',
        options: null,
      },
      {
        id: 38,
        name: 'interior_color',
        alias: 'Interior Color',
        options: null,
      },
      {
        id: 39,
        name: 'is_rental',
        alias: 'Is Rental',
        options: [
          'Yes',
          'No',
        ],
        default: '',
      },
      {
        id: 41,
        name: 'living_quarters',
        alias: 'Living Quarters',
        options: [
          'Yes',
          'No',
        ],
        default: '',
      },
      {
        id: 44,
        name: 'mileage',
        alias: 'Mileage',
        options: null,
        pattern: 'decinumeric',
      },
      {
        id: 46,
        name: 'number_of_awnings',
        alias: 'Number of Awnings',
        options: null,
      },
      {
        id: 48,
        name: 'pull_type',
        alias: 'Pull Type',
        options: [
          'Fifth Wheel',
          'Bumper',
          'Gooseneck',
          'Pintle',
          'Weight Distribution',
        ],
      },
      {
        id: 54,
        name: 'sleeping_capacity',
        alias: 'Sleeping Capacity',
        options: null,
      },
      {
        id: 55,
        name: 'slideouts',
        alias: 'Slideouts',
        options: [
          '1',
          '2',
          '3',
          '4+',
        ],
      },
      {
        id: 64,
        name: 'min_height',
        alias: 'Min Height',
        options: null,
      },
      {
        id: 65,
        name: 'min_width',
        alias: 'Min Width',
        options: null,
      },
      {
        id: 66,
        name: 'min_length',
        alias: 'Min Length',
        options: null,
      },
      {
        id: 67,
        name: 'overall_length',
        alias: 'Overall Length',
        options: null,
      },
      {
        id: 69,
        name: 'daily_rate',
        alias: 'Daily Rate',
        options: null,
        pattern: 'decinumeric',
      },
      {
        id: 70,
        name: 'weekly_rate',
        alias: 'Weekly Rate',
        options: null,
        pattern: 'decinumeric',
      },
      {
        id: 71,
        name: 'monthly_rate',
        alias: 'Monthly Rate',
        options: null,
        pattern: 'decinumeric',
      },
    ],
  },
  {
    id: 5,
    name: 'Semi-Trailer',
    attributes: [
      {
        id: 8,
        name: 'axles',
        alias: 'Axles',
        options: [
          '1',
          '2',
          '3+',
        ],
      },
      {
        id: 18,
        name: 'construction',
        alias: 'Construction',
        options: [
          'Aluminum',
          'Composite',
          'Fiberglass',
          'Galvanized',
          'Hypalon',
          'Other',
        ],
      },
      {
        id: 30,
        name: 'external_link',
        alias: 'External Link',
        options: null,
      },
      {
        id: 40,
        name: 'is_tilt',
        alias: 'Is Tilt',
        options: [
          'Yes',
          'No',
        ],
        default: '',
      },
      {
        id: 48,
        name: 'pull_type',
        alias: 'Pull Type',
        options: [
          'Fifth Wheel',
          'Bumper',
          'Gooseneck',
          'Pintle',
          'Weight Distribution',
        ],
      },
      {
        id: 64,
        name: 'min_height',
        alias: 'Min Height',
        options: null,
      },
      {
        id: 65,
        name: 'min_width',
        alias: 'Min Width',
        options: null,
      },
      {
        id: 66,
        name: 'min_length',
        alias: 'Min Length',
        options: null,
      },
      {
        id: 67,
        name: 'overall_length',
        alias: 'Overall Length',
        options: null,
      },
    ],
  },
  {
    id: 6,
    name: 'Semi-Truck',
    attributes: [
      {
        id: 13,
        name: 'cab_type',
        alias: 'Cab Type',
        options: [
          'Day Cab',
          'Sleeper Cab',
        ],
      },
      {
        id: 16,
        name: 'color',
        alias: 'Color',
        options: [
          'Black',
          'White',
          'Gray',
          'Brown',
          'Blue',
          'Yellow',
          'Red',
          'Pink',
          'Purple',
          'Green',
          'Orange',
          'Silver',
        ],
      },
      {
        id: 30,
        name: 'external_link',
        alias: 'External Link',
        options: null,
      },
      {
        id: 32,
        name: 'fuel_type',
        alias: 'Fuel Type',
        options: [
          'Diesel',
          'Electric',
          'FlexFuel',
          'Gas',
          'Hybrid',
          'Lithium',
        ],
      },
      {
        id: 39,
        name: 'is_rental',
        alias: 'Is Rental',
        options: [
          'Yes',
          'No',
        ],
        default: '',
      },
      {
        id: 44,
        name: 'mileage',
        alias: 'Mileage',
        options: null,
        pattern: 'decinumeric',
      },
      {
        id: 64,
        name: 'min_height',
        alias: 'Min Height',
        options: null,
      },
      {
        id: 65,
        name: 'min_width',
        alias: 'Min Width',
        options: null,
      },
      {
        id: 66,
        name: 'min_length',
        alias: 'Min Length',
        options: null,
      },
      {
        id: 67,
        name: 'overall_length',
        alias: 'Overall Length',
        options: null,
      },
      {
        id: 69,
        name: 'daily_rate',
        alias: 'Daily Rate',
        options: null,
        pattern: 'decinumeric',
      },
      {
        id: 70,
        name: 'weekly_rate',
        alias: 'Weekly Rate',
        options: null,
        pattern: 'decinumeric',
      },
      {
        id: 71,
        name: 'monthly_rate',
        alias: 'Monthly Rate',
        options: null,
        pattern: 'decinumeric',
      },
    ],
  },
  {
    id: 7,
    name: 'Sports Vehicle',
    attributes: [
      {
        id: 1,
        name: 'number_of_batteries',
        alias: '# of Batteries',
        options: [
          '1',
          '2',
          '3+',
        ],
      },
      {
        id: 2,
        name: 'number_of_passengers',
        alias: '# of Passengers',
        options: null,
      },
      {
        id: 16,
        name: 'color',
        alias: 'Color',
        options: [
          'Black',
          'White',
          'Gray',
          'Brown',
          'Blue',
          'Yellow',
          'Red',
          'Pink',
          'Purple',
          'Green',
          'Orange',
          'Silver',
        ],
      },
      {
        id: 30,
        name: 'external_link',
        alias: 'External Link',
        options: null,
      },
      {
        id: 32,
        name: 'fuel_type',
        alias: 'Fuel Type',
        options: [
          'Diesel',
          'Electric',
          'FlexFuel',
          'Gas',
          'Hybrid',
          'Lithium',
        ],
      },
      {
        id: 36,
        name: 'horsepower',
        alias: 'Horsepower',
        options: null,
      },
      {
        id: 39,
        name: 'is_rental',
        alias: 'Is Rental',
        options: [
          'Yes',
          'No',
        ],
        default: '',
      },
      {
        id: 44,
        name: 'mileage',
        alias: 'Mileage',
        options: null,
        pattern: 'decinumeric',
      },
      {
        id: 64,
        name: 'min_height',
        alias: 'Min Height',
        options: null,
      },
      {
        id: 65,
        name: 'min_width',
        alias: 'Min Width',
        options: null,
      },
      {
        id: 66,
        name: 'min_length',
        alias: 'Min Length',
        options: null,
      },
      {
        id: 67,
        name: 'overall_length',
        alias: 'Overall Length',
        options: null,
      },
      {
        id: 69,
        name: 'daily_rate',
        alias: 'Daily Rate',
        options: null,
        pattern: 'decinumeric',
      },
      {
        id: 70,
        name: 'weekly_rate',
        alias: 'Weekly Rate',
        options: null,
        pattern: 'decinumeric',
      },
      {
        id: 71,
        name: 'monthly_rate',
        alias: 'Monthly Rate',
        options: null,
        pattern: 'decinumeric',
      },
    ],
    specificAttributes: [
      {
        'Side by Side (Utility)': [
          {
            Engine: [
              {
                name: 'engineType',
                alias: 'Engine Type',
              },
              {
                name: 'displacement',
                alias: 'Displacement',
              },
              {
                name: 'cooling',
                alias: 'Cooling System',
              },
              {
                name: 'bore',
                alias: 'Bore',
              },
              {
                name: 'stroke',
                alias: 'Stroke',
              },
              {
                name: 'compressionRatio',
                alias: 'Compression Ratio',
              },
              {
                name: 'valveTrain',
                alias: 'Valve train',
              },
              {
                name: 'induction',
                alias: 'Induction',
              },
              {
                name: 'ignition',
                alias: 'Ignition',
              },
            ],
            Drivetrain: [
              {
                name: 'transmission',
                alias: 'Transmission',
                options: [
                  'Automatic',
                  'Manual',
                ],
              },
              {
                name: 'driveline',
                alias: 'Driveline',
              },
              {
                name: 'driveDrivenClutch',
                alias: 'Clutch',
              },
            ],
            Suspension: [
              {
                name: 'frontSuspension',
                alias: 'Front Suspension',
              },
              {
                name: 'frontTravel',
                alias: 'Front Travel',
              },
              {
                name: 'rearSuspension',
                alias: 'Rear Suspension',
              },
              {
                name: 'rearTravel',
                alias: 'Rear Travel',
              },
            ],
            Brakes: [
              {
                name: 'frontBrakingSystem',
                alias: 'Front Braking System',
              },
              {
                name: 'rearBrakingSystem',
                alias: 'Rear Braking System',
              },
            ],
            'Wheels & Tires': [
              {
                name: 'frontTire',
                alias: 'Front Tire',
              },
              {
                name: 'rearTire',
                alias: 'Rear Tire',
              },
            ],
            Dimensions: [
              {
                name: 'overallLength',
                alias: 'Length',
              },
              {
                name: 'overallWidth',
                alias: 'Width',
              },
              {
                name: 'overallHeight',
                alias: 'Height',
              },
              {
                name: 'wheelbase',
                alias: 'Wheelbase',
              },
              {
                name: 'groundClearance',
                alias: 'Ground Clearance',
              },
              {
                name: 'turningRadius',
                alias: 'Turning Radius',
              },
            ],
            Capacities: [
              {
                name: 'fuelCapacity',
                alias: 'Fuel Capacity',
              },
              /* {
                name: 'payloadCapacity',
                alias: 'Payload Capacity',
              }, */
              {
                name: 'towingCapacity',
                alias: 'Towing Capacity',
              },
            ],
            Weights: [
              {
                name: 'curbWeight',
                alias: 'Curb Weight',
              },
            ],
          },
        ],
      },
      {
        'Snowmobile Vehicle': [
          {
            Engine: [
              {
                name: 'engineType',
                alias: 'Engine Type',
              },
              {
                name: 'displacement',
                alias: 'Displacement',
              },
              {
                name: 'cooling',
                alias: 'Cooling System',
              },
              {
                name: 'cylinderLiners',
                alias: 'Cylinder Liners',
              },
              {
                name: 'bore',
                alias: 'Bore',
              },
              {
                name: 'stroke',
                alias: 'Stroke',
              },
              {
                name: 'lubrication',
                alias: 'Lubrication',
              },
              {
                name: 'ignition',
                alias: 'Ignition',
              },
              {
                name: 'engine',
                alias: 'Engine',
              },
              {
                name: 'fuelSystem',
                alias: 'Fuel System',
              },
              {
                name: 'exhaust',
                alias: 'Exhaust',
              },
              {
                name: 'fuelType',
                alias: 'Fuel Type',
              },
            ],
            Drivetrain: [
              {
                name: 'driveSystem',
                alias: 'Drive System',
              },
              {
                name: 'brakeSystemType',
                alias: 'Brake System Type',
              },
              {
                name: 'driveDrivenClutch',
                alias: 'Clutch',
              },
              {
                name: 'gears',
                alias: 'Gears',
              },
              {
                name: 'chainPitch',
                alias: 'Chain Pitch',
              },
              {
                name: 'sprocketChain',
                alias: 'Sprocket Chain',
              },
              {
                name: 'reverse',
                alias: 'Reverse',
              },
            ],
            Suspension: [
              {
                name: 'frontSuspension',
                alias: 'Front Suspension',
              },
              {
                name: 'shockAbsorber',
                alias: 'Shock Absorber',
              },
              {
                name: 'frontTravel',
                alias: 'Front Travel',
              },
              {
                name: 'rearSuspension',
                alias: 'Rear Suspension',
              },
              {
                name: 'frontShocks',
                alias: 'Front Shocks',
              },
              {
                name: 'rearShocks',
                alias: 'Rear Shocks',
              },
              {
                name: 'rearTravel',
                alias: 'Rear Travel',
              },
            ],
            Dimensions: [
              {
                name: 'overallLength',
                alias: 'Overall Length',
              },
              {
                name: 'overallWidth',
                alias: 'Overall Width',
              },
              {
                name: 'overallHeight',
                alias: 'Overall Height',
              },
              {
                name: 'trackWidth',
                alias: 'Track Width',
              },
              {
                name: 'trackLength',
                alias: 'Track Length',
              },
              {
                name: 'trackLug',
                alias: 'Track Lug',
              },
              {
                name: 'trackPitch',
                alias: 'Track Pitch',
              },
              {
                name: 'skiType',
                alias: 'Ski Type',
              },
            ],
            Capacities: [
              {
                name: 'fuelCapacity',
                alias: 'Fuel Capacity',
              },
              {
                name: 'oilCapacity',
                alias: 'Oil Capacity',
              },
              {
                name: 'cooling',
                alias: 'Cooling System',
              },
            ],
          },
        ],
      },
      {
        ATV: [
          {
            Engine: [
              {
                name: 'engineType',
                alias: 'Engine Type',
              },
              {
                name: 'displacement',
                alias: 'Displacement',
              },
              {
                name: 'cooling',
                alias: 'Cooling System',
              },
              {
                name: 'fuelSystem',
                alias: 'Fuel System',
              },
            ],
            Drivetrain: [
              {
                name: 'driveSystem',
                alias: 'Drive System',
              },
              {
                name: 'gears',
                alias: 'Gears',
              },
              {
                name: 'differentialRear',
                alias: 'Differential, Rear',
              },
              {
                name: 'steering',
                alias: 'Steering',
              },
            ],
            Suspension: [
              {
                name: 'frontSuspension',
                alias: 'Front Suspension',
              },
              {
                name: 'frontTravel',
                alias: 'Front Travel',
              },
              {
                name: 'rearSuspension',
                alias: 'Rear Suspension',
              },
              {
                name: 'rearTravel',
                alias: 'Rear Travel',
              },
              {
                name: 'brakes',
                alias: 'Brakes',
              },
              {
                name: 'brakeSystemType',
                alias: 'Brake System Type',
              },
              {
                name: 'parkingBrake',
                alias: 'Parking Brake',
              },
            ],
            'Wheels & Tires': [
              {
                name: 'frontTire',
                alias: 'Front Tire',
              },
              {
                name: 'rearTire',
                alias: 'Rear Tire',
              },
              {
                name: 'wheels',
                alias: 'Wheels',
              },
            ],
            Dimensions: [
              {
                name: 'overallLength',
                alias: 'Overall Length',
              },
              {
                name: 'overallWidth',
                alias: 'Overall Width',
              },
              {
                name: 'overallHeight',
                alias: 'Overall Height',
              },
              {
                name: 'wheelbase',
                alias: 'Wheelbase',
              },
              {
                name: 'groundClearance',
                alias: 'Ground Clearance',
              },
            ],
            Capacities: [
              {
                name: 'cargoCapacity',
                alias: 'Cargo Capacity',
              },
              {
                name: 'rackCapacityFront',
                alias: 'Rack Capacity, Front',
              },
              {
                name: 'rackCapacityRear',
                alias: 'Rack Capacity, Rear',
              },
              {
                name: 'fuelCapacity',
                alias: 'Fuel Capacity',
              },
            ],
            Weights: [
              {
                name: 'dryWeight',
                alias: 'Dry Weight',
              },
            ],
          },
        ],
      },
    ],
  },
  {
    id: 8,
    name: 'Trailer or Truck Bed',
    attributes: [
      {
        id: 8,
        name: 'axles',
        alias: 'Axles',
        options: [
          '1',
          '2',
          '3+',
        ],
      },
      {
        id: 16,
        name: 'color',
        alias: 'Color',
        options: [
          'Black',
          'White',
          'Gray',
          'Brown',
          'Blue',
          'Yellow',
          'Red',
          'Pink',
          'Purple',
          'Green',
          'Orange',
          'Silver',
        ],
      },
      {
        id: 18,
        name: 'construction',
        alias: 'Construction',
        options: [
          'Aluminum',
          'Composite',
          'Fiberglass',
          'Galvanized',
          'Hypalon',
          'Other',
        ],
      },
      {
        id: 19,
        name: 'conversion_company',
        alias: 'Conversion Company',
        options: [
          'Bunkhouse of America',
          'Compass Conversions',
          'Outback Conversions',
          'Outlaw Conversions',
          'Trail Boss Conversions',
          'West Texas Conversions',
        ],
      },
      {
        id: 20,
        name: 'custom_conversion_company',
        alias: 'Custom Conversion Company',
        options: null,
      },
      {
        id: 39,
        name: 'is_rental',
        alias: 'Is Rental',
        options: [
          'Yes',
          'No',
        ],
        default: '',
      },
      {
        id: 40,
        name: 'is_tilt',
        alias: 'Is Tilt',
        options: [
          'Yes',
          'No',
        ],
        default: '',
      },
      {
        id: 41,
        name: 'living_quarters',
        alias: 'Living Quarters',
        options: [
          'Yes',
          'No',
        ],
        default: '',
      },
      {
        id: 45,
        name: 'nose_type',
        alias: 'Nose Type',
        options: [
          'Flat',
          'Round',
          'V Front',
          'V Front w Ramp',
        ],
      },
      {
        id: 48,
        name: 'pull_type',
        alias: 'Pull Type',
        options: [
          'Fifth Wheel',
          'Bumper',
          'Gooseneck',
          'Pintle',
          'Weight Distribution',
        ],
      },
      {
        id: 49,
        name: 'ramps',
        alias: 'Ramps',
        options: null,
      },
      {
        id: 50,
        name: 'roof_type',
        alias: 'Roof Type',
        options: null,
      },
      {
        id: 54,
        name: 'sleeping_capacity',
        alias: 'Sleeping Capacity',
        options: null,
      },
      {
        id: 55,
        name: 'slideouts',
        alias: 'Slideouts',
        options: [
          '1',
          '2',
          '3',
          '4+',
        ],
      },
      {
        id: 57,
        name: 'subtitle',
        alias: 'Subtitle',
        options: null,
      },
      {
        id: 64,
        name: 'min_height',
        alias: 'Min Height',
        options: null,
      },
      {
        id: 65,
        name: 'min_width',
        alias: 'Min Width',
        options: null,
      },
      {
        id: 66,
        name: 'min_length',
        alias: 'Min Length',
        options: null,
      },
      {
        id: 67,
        name: 'overall_length',
        alias: 'Overall Length',
        options: null,
      },
      {
        id: 68,
        name: 'side_wall_height',
        alias: 'Side Wall Height',
        options: null,
      },
      {
        id: 69,
        name: 'daily_rate',
        alias: 'Daily Rate',
        options: null,
        pattern: 'decinumeric',
      },
      {
        id: 70,
        name: 'weekly_rate',
        alias: 'Weekly Rate',
        options: null,
        pattern: 'decinumeric',
      },
      {
        id: 71,
        name: 'monthly_rate',
        alias: 'Monthly Rate',
        options: null,
        pattern: 'decinumeric',
      },
      {
        id: 72,
        name: 'suspension_type',
        alias: 'Suspension Type',
        options: [
          'Leaf Spring Suspension',
          'Torsion Suspension',
          'Heavy Duty Adjustable Suspension',
          'Air Ride Suspension',
        ],
      },
    ],
  },
  {
    id: 9,
    name: 'Watercraft',
    attributes: [
      {
        id: 16,
        name: 'color',
        alias: 'Color',
        options: [
          'Black',
          'White',
          'Gray',
          'Brown',
          'Blue',
          'Yellow',
          'Red',
          'Pink',
          'Purple',
          'Green',
          'Orange',
          'Silver',
        ],
      },
      {
        id: 18,
        name: 'construction',
        alias: 'Construction',
        options: [
          'Aluminum',
          'Composite',
          'Fiberglass',
          'Galvanized',
          'Hypalon',
          'Other',
        ],
      },
      {
        id: 21,
        name: 'dead_rise',
        alias: 'Deadrise',
        options: null,
      },
      {
        id: 23,
        name: 'draft',
        alias: 'Draft',
        options: null,
      },
      {
        id: 25,
        name: 'dry_weight',
        alias: 'Dry Weight',
        options: null,
      },
      {
        id: 27,
        name: 'engine',
        alias: 'Engine',
        options: null,
      },
      {
        id: 28,
        name: 'engine_hours',
        alias: 'Engine Hours',
        options: null,
      },
      {
        id: 29,
        name: 'engine_size',
        alias: 'Engine Size',
        options: [
          '2-Stroke',
          '4-Cylinder',
          '6-Cylinder',
          '8-Cylinder',
          'Other',
        ],
      },
      {
        id: 30,
        name: 'external_link',
        alias: 'External Link',
        options: null,
      },
      {
        id: 31,
        name: 'fuel_capacity',
        alias: 'Fuel Capacity',
        options: null,
      },
      {
        id: 32,
        name: 'fuel_type',
        alias: 'Fuel Type',
        options: [
          'Diesel',
          'Electric',
          'FlexFuel',
          'Gas',
          'Hybrid',
          'Lithium',
        ],
      },
      {
        id: 36,
        name: 'horsepower',
        alias: 'Horsepower',
        options: null,
      },
      {
        id: 37,
        name: 'hull_type',
        alias: 'Hull Type',
        options: null,
      },
      {
        id: 39,
        name: 'is_rental',
        alias: 'Is Rental',
        options: [
          'Yes',
          'No',
        ],
        default: '',
      },
      {
        id: 47,
        name: 'propulsion',
        alias: 'Propulsion',
        options: [
          'Air Propeller',
          'Direct Drive',
          'Jet Drive',
          'Other',
          'Outboard',
          'Pod Drive',
          'Sail',
          'Stern',
          'Surface Drive',
          'V Drive',
        ],
      },
      {
        id: 59,
        name: 'total_weight_capacity',
        alias: 'Total Weight Capacity',
        options: null,
      },
      {
        id: 60,
        name: 'transom',
        alias: 'Transom',
        options: null,
      },
      {
        id: 63,
        name: 'wet_weight',
        alias: 'Wet Weight',
        options: null,
      },
      {
        id: 64,
        name: 'min_height',
        alias: 'Min Height',
        options: null,
      },
      {
        id: 65,
        name: 'min_width',
        alias: 'Min Width',
        options: null,
      },
      {
        id: 66,
        name: 'min_length',
        alias: 'Min Length',
        options: null,
      },
      {
        id: 67,
        name: 'overall_length',
        alias: 'Overall Length',
        options: null,
      },
      {
        id: 69,
        name: 'daily_rate',
        alias: 'Daily Rate',
        options: null,
        pattern: 'decinumeric',
      },
      {
        id: 70,
        name: 'weekly_rate',
        alias: 'Weekly Rate',
        options: null,
        pattern: 'decinumeric',
      },
      {
        id: 71,
        name: 'monthly_rate',
        alias: 'Monthly Rate',
        options: null,
        pattern: 'decinumeric',
      },
    ],
  },
  {
    id: 10,
    name: 'Vehicle',
    attributes: [
      {
        id: 12,
        name: 'body',
        alias: 'Body',
        options: [
          'Convertible',
          'Coupe',
          'Hatchback',
          'Jeep',
          'MUV / SUV',
          'Sedan',
          'Van',
          'Wagon',
        ],
      },
      {
        id: 15,
        name: 'city_mpg',
        alias: 'City MPG',
        options: null,
      },
      {
        id: 16,
        name: 'color',
        alias: 'Color',
        options: [
          'Black',
          'White',
          'Gray',
          'Brown',
          'Blue',
          'Yellow',
          'Red',
          'Pink',
          'Purple',
          'Green',
          'Orange',
          'Silver',
        ],
      },
      {
        id: 22,
        name: 'doors',
        alias: 'Doors',
        options: [
          '2',
          '4',
        ],
      },
      {
        id: 24,
        name: 'drive_train',
        alias: 'Drive Train',
        options: [
          'Rear Wheel Drive',
          '4WD',
          'All Wheel Drive',
          'Front Wheel Drive',
        ],
      },
      {
        id: 29,
        name: 'engine_size',
        alias: 'Engine Size',
        options: [
          '2-Stroke',
          '4-Cylinder',
          '6-Cylinder',
          '8-Cylinder',
          'Other',
        ],
      },
      {
        id: 30,
        name: 'external_link',
        alias: 'External Link',
        options: null,
      },
      {
        id: 32,
        name: 'fuel_type',
        alias: 'Fuel Type',
        options: [
          'Diesel',
          'Electric',
          'FlexFuel',
          'Gas',
          'Hybrid',
          'Lithium',
        ],
      },
      {
        id: 34,
        name: 'highway_mpg',
        alias: 'Highway MPG',
        options: null,
      },
      {
        id: 38,
        name: 'interior_color',
        alias: 'Interior Color',
        options: null,
      },
      {
        id: 39,
        name: 'is_rental',
        alias: 'Is Rental',
        options: [
          'Yes',
          'No',
        ],
        default: '',
      },
      {
        id: 44,
        name: 'mileage',
        alias: 'Mileage',
        options: null,
        pattern: 'decinumeric',
      },
      {
        id: 52,
        name: 'series',
        alias: 'Series',
        options: null,
      },
      {
        id: 61,
        name: 'transmission',
        alias: 'Transmission',
        options: [
          'Automatic',
          'Manual',
        ],
      },
      {
        id: 62,
        name: 'transmission_speed',
        alias: 'Transmission Speed',
        options: [
          '3',
          '4',
          '5',
          '6',
          '7',
          '8',
          '9',
          '10',
        ],
      },
      {
        id: 64,
        name: 'min_height',
        alias: 'Min Height',
        options: null,
      },
      {
        id: 65,
        name: 'min_width',
        alias: 'Min Width',
        options: null,
      },
      {
        id: 66,
        name: 'min_length',
        alias: 'Min Length',
        options: null,
      },
      {
        id: 67,
        name: 'overall_length',
        alias: 'Overall Length',
        options: null,
      },
      {
        id: 69,
        name: 'daily_rate',
        alias: 'Daily Rate',
        options: null,
        pattern: 'decinumeric',
      },
      {
        id: 70,
        name: 'weekly_rate',
        alias: 'Weekly Rate',
        options: null,
        pattern: 'decinumeric',
      },
      {
        id: 71,
        name: 'monthly_rate',
        alias: 'Monthly Rate',
        options: null,
        pattern: 'decinumeric',
      },
    ],
    specificAttributes: [
      {
        Motorcycle: [
          {
            Engine: [
              {
                name: 'engineType',
                alias: 'Engine Type',
              },
              {
                name: 'displacement',
                alias: 'Displacement',
              },
              {
                name: 'cooling',
                alias: 'Cooling System',
              },
              {
                name: 'bore',
                alias: 'Bore',
              },
              {
                name: 'stroke',
                alias: 'Stroke',
              },
              {
                name: 'compressionRatio',
                alias: 'Compression Ratio',
              },
              {
                name: 'valveTrain',
                alias: 'Valve train',
              },
              {
                name: 'induction',
                alias: 'Induction',
              },
              {
                name: 'ignition',
                alias: 'Ignition',
              },
              {
                name: 'emissions',
                alias: 'Emissions',
              },
            ],
            Drivetrain: [
              {
                name: 'transmission',
                alias: 'Transmission',
                options: [
                  'Automatic',
                  'Manual',
                ],
              },
              {
                name: 'finalDrive',
                alias: 'Final Drive',
              },
            ],
            Suspension: [
              {
                name: 'frontSuspension',
                alias: 'Front Suspension',
              },
              {
                name: 'frontTravel',
                alias: 'Front Travel',
              },
              {
                name: 'rearSuspension',
                alias: 'Rear Suspension',
              },
              {
                name: 'rearTravel',
                alias: 'Rear Travel',
              },
              {
                name: 'brakes',
                alias: 'Brakes',
              },
              {
                name: 'frontBrakingSystem',
                alias: 'Front braking system',
              },
              {
                name: 'rearBrakingSystem',
                alias: 'Rear braking system',
              },
            ],
            'Wheels & Tires': [
              {
                name: 'frontTire',
                alias: 'Front Tire',
              },
              {
                name: 'rearTire',
                alias: 'Rear Tire',
              },
            ],
            Dimensions: [
              {
                name: 'wheelbase',
                alias: 'Wheelbase',
              },
              {
                name: 'raketrail',
                alias: 'Rake/trail',
              },
              {
                name: 'seatHeight',
                alias: 'Seat Height',
              },
              {
                name: 'groundClearance',
                alias: 'Ground Clearance',
              },
            ],
            Capacities: [
              {
                name: 'fuelCapacity',
                alias: 'Fuel Capacity',
              },
              {
                name: 'curbWeight',
                alias: 'Curb Weight',
              },
            ],
            Other: [
              {
                name: 'modelId',
                alias: 'Model Id',
              },
            ],
          },
        ],
      },
    ],
  },
];
